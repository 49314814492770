<template>
    <div class="pageContainer" style="padding:0">
        <iframe v-if="isChange" id="iframe" name="showIframe" height="100%" width="100%" :src="src"
            frameborder="0"></iframe>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            src: '',
            isChange: false
        }
    },

    watch: {
        '$store.state.iframeUrl'(n, o) {
            let _this = this
            this.isChange = false
            console.log(171717, this.isChange)
            this.isChange = true
            this.$nextTick(() => {
                _this.src = n
                _this.$forceUpdate()

            })
            console.log(171717, this.isChange)
            console.log(2727, n)
        }
    },
    mounted() {
        // console.log(19, this.$store.state.iframeUrl)
    },
    methods: {

    }

}
</script>
